.QRDisplay-main {
  width: 343px;
  height: 569px;
  border-radius: 10px;
  background-color: #15171a;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.QRDisplay-instructions {
  color: white;
  margin: 20px 40px;
  font-size: 27px;
	font-weight: 400;
}

.QRDisplay-code {
  background-color: white;
  margin: 0 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 300px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.QRDisplay-code-text {
  color: white;
  margin-bottom: 30px;
}

.QRDisplay-bottom {
  border-top: 0.5px solid rgba(255, 255, 255, 0.15);
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.QRDisplay-bottom-waiting {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-top: 10px;
}
